import { ApplicationConfig, DEFAULT_CURRENCY_CODE, ErrorHandler, LOCALE_ID } from '@angular/core'
import { provideRouter, withViewTransitions } from '@angular/router'
import { provideHttpClient, withInterceptors } from '@angular/common/http'
import { OAuthStorage, provideOAuthClient } from 'angular-oauth2-oidc'
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async'
import { routes } from './app.routes'
import { tokenInterceptor } from '@core/interceptors/token.interceptor'
import localeFrExtra from '@angular/common/locales/extra/es-SV'
import { PRECONNECT_CHECK_BLOCKLIST, registerLocaleData } from '@angular/common'
import localeFr from '@angular/common/locales/es-SV'
import { GlobalErrorHandler } from '@core/interceptors/global-error.handler'
import { TablerIconsProvider } from 'angular-tabler-icons'
import * as TablerIcons from 'angular-tabler-icons/icons'
import { provideToastr } from 'ngx-toastr'
import { provideHighlightOptions } from 'ngx-highlightjs'

// Necesario para que OAuth funcione con localStorage
export function storageFactory(): OAuthStorage {
	return localStorage
}

registerLocaleData(localeFr, 'es-SV', localeFrExtra)

export const appConfig: ApplicationConfig = {
	providers: [
		provideRouter(routes, withViewTransitions()),
		provideHttpClient(withInterceptors([tokenInterceptor])),
		provideOAuthClient(),
		provideAnimationsAsync(),
		provideToastr({
			timeOut: 5000,
			positionClass: 'toast-top-right',
			preventDuplicates: true,
			resetTimeoutOnDuplicate: true,
		}),
		{ provide: OAuthStorage, useFactory: storageFactory },
		{ provide: LOCALE_ID, useValue: 'es-SV' },
		{ provide: DEFAULT_CURRENCY_CODE, useValue: 'USD' },
		{
			provide: ErrorHandler,
			useClass: GlobalErrorHandler,
		},
		TablerIconsProvider.pick(TablerIcons, {
			ignoreWarnings: true,
		}),
		{
			provide: PRECONNECT_CHECK_BLOCKLIST,
			useValue: ['https://integraciones.bcr.gob.sv', 'https://wallet-onboarding-dev.s3.amazonaws.com'],
		},
		provideHighlightOptions({
			lineNumbersLoader: () => import('ngx-highlightjs/line-numbers'),
			coreLibraryLoader: () => import('highlight.js/lib/core'),
			languages: {
				typescript: () => import('highlight.js/lib/languages/typescript'),
				json: () => import('highlight.js/lib/languages/json'),
				sql: () => import('highlight.js/lib/languages/sql'),
			},
			themePath: 'assets/scss/stackoverflow-light.min.css',
		}),
	],
}
